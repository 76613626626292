import { Avatar, Skeleton } from '@mui/material';
import { FunctionComponent } from 'react';
import './Avatar.component.scss';
interface AvatarComponentProps {
  avatar?: string;
  userName: string;
  width?: number;
  height?: number;
}

export const AvatarComponent: FunctionComponent<AvatarComponentProps> = (
  props
) => {
  const { avatar, userName, width, height } = props;
  return (
    <span>
      {!userName ? (
        <Skeleton variant="circular" width={width} height={height} />
      ) : (
        <span className="avatar-name-container">
          <Avatar className="avatar-name-container" src={avatar}></Avatar>
        </span>
      )}
    </span>
  );
};
