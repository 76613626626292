import styled from 'styled-components';

const TagContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const TableWrapper = styled.div`
  height: 400;
  width: '50%';
  margin-top: 20px;
`;

export { TagContainer, TableWrapper };
