import React, { FunctionComponent } from 'react';
import { Snackbar } from '@mui/material';

interface Props {
  open: boolean;
  onClose: () => void;
  autoHideDuration?: number;
  children: React.ReactNode;
  vertical?: 'top' | 'bottom';
  horizontal?: 'left' | 'right' | 'center';
}

const SnackbarComponent: FunctionComponent<Props> = ({
  open,
  onClose,
  autoHideDuration = 2000,
  children,
  vertical = 'top',
  horizontal = 'center',
}) => {
  return (
    <Snackbar
      open={open}
      autoHideDuration={autoHideDuration}
      onClose={onClose}
      anchorOrigin={{
        vertical,
        horizontal,
      }}
    >
      <div> {children}</div>
    </Snackbar>
  );
};
export default SnackbarComponent;
