import { LocationHeadCell } from 'interfaces/location.interface';

export const projectHeadCells: readonly LocationHeadCell[] = [
  {
    id: 'id',
    numeric: false,
    disablePadding: true,
    sort: true,
    label: 'Location Id',
  },
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'Name',
    sort: true,
  },
  {
    id: 'description',
    numeric: false,
    disablePadding: false,
    label: 'Description',
    sort: false,
  },
  {
    id: 'contactPersonEmail',
    numeric: false,
    disablePadding: false,
    label: 'Contact Person Email',
    sort: true,
  },
  {
    id: 'address',
    numeric: false,
    disablePadding: false,
    label: 'Address',
    sort: false,
  },
];

export const initialProjectDetails = {
  name: '',
  description: '',
  firstName: '',
  lastName: '',
  email: '',
  secondaryEmail: '',
  address: '',
};

export type Order = 'asc' | 'desc';
