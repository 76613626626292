import React, { useState } from 'react';
import { Box, Tab, Tabs } from '@mui/material';

import { TagContainer } from './style';
import UsersTags from 'pages/Users-tags/UsersTags.Component';
import { SearchTags } from './SearchTags.component';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const Tags: React.FunctionComponent = () => {
  const [selectedTab, setSelectedTab] = useState<0 | 1>(0);

  function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
      </div>
    );
  }

  const handleChange = (event: React.SyntheticEvent, newValue: 0 | 1) => {
    setSelectedTab(newValue);
  };

  return (
    <TagContainer>
      <Tabs value={selectedTab} onChange={handleChange}>
        <Tab label="Tags" />
        <Tab label="Users" />
      </Tabs>
      <CustomTabPanel value={selectedTab} index={0}>
        <SearchTags />
      </CustomTabPanel>
      <CustomTabPanel value={selectedTab} index={1}>
        <UsersTags />
      </CustomTabPanel>
    </TagContainer>
  );
};

export default Tags;
