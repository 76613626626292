import {
  LocationOn,
  Person,
  Description,
  Factory,
  AppSettingsAlt,
  PermPhoneMsg,
  Tag,
  Code,
} from '@mui/icons-material';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';

export const SidebarData = [
  {
    title: 'Project',
    path: '/',
    icon: <Factory />,
  },
  {
    title: 'Location',
    path: '/location',
    icon: <LocationOn />,
  },
  {
    title: 'Users',
    path: '/users',
    icon: <Person />,
  },
  {
    title: 'Reports',
    path: '/reports',
    icon: <Description />,
  },
  {
    title: 'Provider Setup',
    path: '/provider-setup',
    icon: <AdminPanelSettingsIcon />,
  },
  {
    title: 'Driver Function',
    path: '/driverfunction',
    icon: <AppSettingsAlt />,
  },
  {
    title: 'Teams',
    path: '/teams',
    icon: <PermPhoneMsg />,
  },
  {
    title: 'Tags',
    path: '/tags',
    icon: <Tag />,
  },
  {
    title: 'Embedded IFrame',
    path: '/embeddedIFrame',
    icon: <Code />,
  },
];
