/*******************************************************
Copyright (C) 2024 H2 Digital GmbH - info@h2-digital.com , Inc - All Rights Reserved
Proprietary and confidential.
Unauthorized copying of this file, via any medium is strictly prohibited.
Written by Silpa Sivadas, silpa.sivadas@h2-digital.com, January 2024
*******************************************************/
import { Fragment, FunctionComponent, useEffect, useState } from 'react';
import './TSOSetup.scss';
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Paper,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
} from '@mui/material';
import { ProjectFragment } from './ProjectSetup';
import { UserFragment } from './UserSetup';
import SProjectService from 'services/project/project.service';
import SLocationService from 'services/location/location.service';
import SUserService from 'services/user/user.service';
import { DomainNames, IUserDetails } from 'interfaces/user.interface';
import { Description, Factory, Person } from '@mui/icons-material';
import AttachEmailIcon from '@mui/icons-material/AttachEmail';
import { TeamsFragment } from './TeamsSetup';
import { PowerBIFragment } from './PowerBIReportSetup';
import { useSnackbar } from 'components/snackbar/SnackbarProvider';
import { getInformationBarrierScript } from 'utils/informationBarrier.utils';
import { TeamsFormData } from 'interfaces/teams.interface';
import { ILocationDetails } from 'interfaces/location.interface';
import { LocationType } from 'constants/location';

const TSOSetup: FunctionComponent = () => {
  const defaultLocationDetails = {
    name: '',
    description: '',
    H2DigitalType: LocationType.Provider,
    cOGEDigitization: 'D-no',
    address: '',
  };

  const [activeStep, setActiveStep] = useState(0);
  const [isNextLoading, setNextLoading] = useState(false);

  const [providerLocationDetails, setProviderLocationDetails] = useState<
    Partial<ILocationDetails>
  >(defaultLocationDetails);
  const [newUserDetails, setNewUserDetails] = useState<IUserDetails>();
  const emptyBlob = new Blob([], { type: 'application/octet-stream' });

  const [projectLogoFile, setProjectLogoFile] = useState<Blob>(emptyBlob);
  const [projectImageFile, setProjectImageFile] = useState<Blob>(emptyBlob);

  const [isCreateProjectEnabled, setCreateProjectEnabled] = useState(false);

  const [isReportAdded, setReportsAdded] = useState(false);
  const [isTeamsCreated, setTeamsCreated] = useState(false);

  const [script, setScript] = useState<string | undefined>(undefined);
  const [isCopied, setIsCopied] = useState(false);
  const { showSnackbar } = useSnackbar();
  const [userPrincipalName, setUserPrincipalName] = useState<string>();
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const skipToLast = () => {
    setActiveStep(4); //last step
  };
  const [teamsFormData, setTeamsFormData] = useState<TeamsFormData>({
    teamsDetails: {
      teamsName: '',
      channelName: '',
      teamsDescription: '',
      channelDescription: '',
      teamsId: '',
      channelId: '',
      notificationLink: '',
    },

    memberAADObjectId: '',
    ownerAADObjectId: '',
    projectId: '',
  });

  const handleReset = () => {
    setActiveStep(0);
    setProviderLocationDetails(defaultLocationDetails);
    setNewUserDetails(undefined);
  };

  const createNewProject = () => {
    if (providerLocationDetails.name) {
      setNextLoading(true);
      const providerDetailsWithTag = {
        ...providerLocationDetails,
        goLiveDate: new Date().toISOString(),
        tags: [{ key: 'Provider', value: providerLocationDetails.name }],
      };
      SProjectService.createNewProvider(providerDetailsWithTag)
        .then((locationDetails) => {
          setProviderLocationDetails(locationDetails);
          SLocationService.uploadImage(projectImageFile, locationDetails.id);
          SLocationService.uploadLogo(projectLogoFile, locationDetails.id);
          setProjectLogoFile(emptyBlob);
          setProjectImageFile(emptyBlob);
          handleNext();
          showSnackbar('Provider project creation success!', 'success');
        })
        .catch(() => {
          showSnackbar('Provider project creation failed!', 'error');
        })
        .finally(() => setNextLoading(false));
    }
  };

  useEffect(() => {
    SUserService.getUser().then((response: any) => {
      setUserPrincipalName(response.userPrincipalName);
    });
  });

  useEffect(() => {
    if (newUserDetails?.aadObjectId) handleNext();
  }, [newUserDetails?.aadObjectId]);

  const steps = [
    {
      key: 'project',
      label: (
        <div>
          {providerLocationDetails.projectId
            ? `Created Project: ${providerLocationDetails.name}`
            : `Provider Details`}
        </div>
      ),
      icon: <Factory className="stepper-icon" />,
      description: `Please fill in the Provider Location Details.`,
      fragment: (
        <ProjectFragment
          providerLocationDetails={providerLocationDetails}
          setProviderLocationDetails={setProviderLocationDetails}
          projectLogoFile={projectLogoFile}
          setProjectLogoFile={setProjectLogoFile}
          projectImageFile={projectImageFile}
          setProjectImageFile={setProjectImageFile}
          setCreateProjectEnabled={setCreateProjectEnabled}
        />
      ),

      nextButton: 'Create Provider',
      nextButtonFunction: createNewProject,
      isDisabled: isCreateProjectEnabled,
    },
    {
      key: 'powerbi',
      label: 'PowerBI Reports',
      icon: <Description className="stepper-icon" />,
      description: `Please add all the relevant powerBI Report details for the Provider.`,
      fragment: (
        <PowerBIFragment
          setReportsAdded={setReportsAdded}
          isReportAdded={isReportAdded}
          locationId={providerLocationDetails.id}
        />
      ),
      nextButton: isReportAdded ? 'Continue' : 'Skip',
      isDisabled: false,
    },
    {
      key: 'user',
      label: (
        <div>
          {newUserDetails?.emailAddress
            ? `Created User: ${newUserDetails.emailAddress}`
            : `User Details`}
        </div>
      ),

      icon: <Person className="stepper-icon" />,
      description: `Please fill in the Provider user details.`,
      fragment: (
        <UserFragment
          setNewUserDetails={setNewUserDetails}
          providerLocationDetails={providerLocationDetails}
        />
      ),
      nextButton: 'Skip',
      nextButtonFunction: skipToLast, //go to end step
    },
    {
      key: 'teams',
      label: 'Teams Details',
      icon: <AttachEmailIcon className="stepper-icon" />,
      description: `Please fill in the MSTeams Details of the Provider.`,
      fragment: (
        <TeamsFragment
          userName={newUserDetails?.emailAddress}
          formData={teamsFormData}
          setFormData={setTeamsFormData}
          setTeamsCreated={setTeamsCreated}
          locationId={providerLocationDetails.id}
          userAADObjectId={newUserDetails?.aadObjectId}
        />
      ),
    },
  ];
  const handleCopyClick = () => {
    if (script) {
      navigator.clipboard.writeText(script);
      setIsCopied(true);
      setTimeout(() => {
        setIsCopied(false);
      }, 2000);
    }
  };
  useEffect(() => {
    if (
      isTeamsCreated &&
      teamsFormData.teamsDetails.teamsName &&
      userPrincipalName
    ) {
      const originalTeamsName = teamsFormData.teamsDetails.teamsName;
      //TODO: get ms365 group (email) id and add unique segment,policy names
      setScript(
        getInformationBarrierScript(
          originalTeamsName,
          originalTeamsName,
          'MemberOf',
          originalTeamsName.replace(/\s+/g, '') + DomainNames.h2Digital,
          userPrincipalName
        ) as string
      );
    }
  }, [isTeamsCreated]);
  return (
    <>
      <div className="page-container">
        <Grid container spacing={2} className="main-grid-container">
          <Grid xs={12} xl={10}>
            <Box>
              <Stepper activeStep={activeStep} orientation="vertical">
                {steps.map((step) => (
                  <Step key={step.key}>
                    <StepLabel
                      StepIconComponent={() => (
                        <div className="stepper-icon-container">
                          {step.icon}
                        </div>
                      )}
                    >
                      {step.label}
                    </StepLabel>
                    <StepContent>
                      <Typography className="step-description">
                        {step.description}
                      </Typography>
                      <Fragment>{step.fragment}</Fragment>
                      <Box sx={{ mb: 2 }}>
                        <div>
                          {step.nextButton && (
                            <Button
                              className="step-button"
                              variant="contained"
                              onClick={step.nextButtonFunction || handleNext}
                              sx={{ mt: 1, mr: 1 }}
                              disabled={isNextLoading || step.isDisabled}
                            >
                              {isNextLoading ? (
                                <CircularProgress size={'20px'} />
                              ) : (
                                step.nextButton
                              )}
                            </Button>
                          )}
                        </div>
                      </Box>
                    </StepContent>
                  </Step>
                ))}
              </Stepper>
              {activeStep === steps.length && (
                <Paper square elevation={0} sx={{ p: 3 }}>
                  {isTeamsCreated && (
                    <Typography>
                      Please add the Barrier Policies. Open your Windows
                      PowerShell in Administrator Mode and copy and run the
                      following code snippet.
                      <div className="code-snippet-container">
                        <code className="code-snippet-code">{script}</code>
                        <div className="copy-button-container">
                          <button
                            className="copy-button"
                            onClick={handleCopyClick}
                          >
                            {isCopied ? 'Copied!' : 'Copy to Clipboard'}
                          </button>
                        </div>
                      </div>{' '}
                    </Typography>
                  )}

                  <Button
                    variant="contained"
                    onClick={handleReset}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    Create a New Provider
                  </Button>
                </Paper>
              )}
            </Box>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default TSOSetup;
