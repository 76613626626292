/* eslint-disable @typescript-eslint/no-explicit-any */
import { Grid } from '@mui/material';
import SProjectService from 'services/project/project.service';

import _ from 'lodash';
import { useEffect, useState } from 'react';
import { AnalyticsGraph } from './AnalyticsGraph';

export default function ProjectAnalytics() {
  const [count, setCount] = useState({
    createdAt: 0,
    updatedAt: 0,
    deletedAt: 0,
    totalCount: 0,
  });
  const [graphData, setGraphData] = useState<{
    createdAt: any;
    updatedAt: any;
    deletedAt: any;
  }>({
    createdAt: [],
    updatedAt: [],
    deletedAt: [],
  });

  // eslint-disable-next-line @typescript-eslint/no-shadow, @typescript-eslint/no-explicit-any
  const generateGraphData = (data: any) => {
    const dataArray = Object.entries(data).map(([date, value]) => {
      return { name: date, value: value };
    });
    return dataArray;
  };
  useEffect(() => {
    SProjectService.getProjectAnalytics()
      .then((res) => {
        setGraphData({
          createdAt: generateGraphData(res.createdAt),
          updatedAt: generateGraphData(res.updatedAt),
          deletedAt: generateGraphData(res.deletedAt),
        });

        setCount({
          createdAt: _.values(res.createdAt).reduce(
            (accumulator, currentValue) => accumulator + currentValue,
            0
          ),
          updatedAt: _.values(res.updatedAt).reduce(
            (accumulator, currentValue) => accumulator + currentValue,
            0
          ),
          deletedAt: _.values(res.deletedAt).reduce(
            (accumulator, currentValue) => accumulator + currentValue,
            0
          ),
          totalCount: res.totalCount,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <>
      <Grid className="project-overview-row-container" container spacing={1}>
        <Grid className="project-overview-cell-container" item xs={2.7}>
          <div className="project-overview-total-count-cell">
            <Grid
              item
              xs={12}
              className="project-overview-row-basic project-overview-cell-full-row1"
            >
              Total Projects
            </Grid>
            <Grid
              item
              xs={12}
              className="project-overview-row-basic project-overview-cell-full-row2"
            >
              {count.totalCount}
            </Grid>
          </div>
        </Grid>
        <Grid className="project-overview-cell-container" item xs={2.7}>
          <div className="project-overview-row-basic project-overview-cell-row1">
            <div className="project-overview-cell-column1">
              Total Projects Created in Last Week
            </div>
            <div className="project-overview-cell-column2">
              {count.createdAt}
            </div>
          </div>
          <div className="project-overview-row-basic project-overview-cell-row2">
            <AnalyticsGraph data={graphData.createdAt} color={'#7072B4'} />
          </div>
        </Grid>
        {/* We uncomment the below lines of code, when Soft Remove functionality is implemented. */}
        {/* <Grid className="project-overview-cell-container" item xs={2.7}>
          <div className="project-overview-row-basic project-overview-cell-row1">
            <div className="project-overview-cell-column1">
              Total Projects Deleted in Last Week
            </div>
            <div className="project-overview-cell-column2">
              {count.deletedAt}
            </div>
          </div>
          <div className="project-overview-row-basic project-overview-cell-row2">
            <AnalyticsGraph data={graphData.deletedAt} color={'#FF8749'} />
          </div>
        </Grid> */}
        <Grid className="project-overview-cell-container" item xs={2.7}>
          <div className="project-overview-row-basic project-overview-cell-row1">
            <div className="project-overview-cell-column1">
              Total Projects Updated in Last Week
            </div>
            <div className="project-overview-cell-column2">
              {count.updatedAt}
            </div>
          </div>
          <div className="project-overview-row-basic project-overview-cell-row2">
            <AnalyticsGraph data={graphData.updatedAt} color={'Teal'} />
          </div>
        </Grid>
      </Grid>
    </>
  );
}
