import { Grid } from '@mui/material';
import './TSOSetup.scss';
import { Dispatch, FunctionComponent, SetStateAction } from 'react';

import Reports from 'pages/Reports/Reports';
interface ITeamsSetupProps {
  locationId: number | undefined;
  isReportAdded: boolean;

  setReportsAdded: Dispatch<SetStateAction<boolean>>;
}
export const PowerBIFragment: FunctionComponent<ITeamsSetupProps> = (props) => {
  const { locationId, isReportAdded, setReportsAdded } = props;

  return (
    <Grid
      container
      spacing={2}
      xs={12}
      className={`project-container ${
        !isReportAdded ? 'project-container-button' : ''
      }`}
    >
      {isReportAdded}
      <Reports
        setReportsAdded={setReportsAdded}
        isTSOSetup={true}
        locationId={locationId}
      />
    </Grid>
  );
};
