import LogoutIcon from '@mui/icons-material/Logout';
import {
  Card,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Popover,
} from '@mui/material';
import SUserService from '../../services/user/user.service';
import { useEffect, useState } from 'react';
import './ProfileContainer.component.scss';

import SAuthService from '../../services/auth/auth.service';
import { AvatarComponent } from '../avatar/Avatar.component';

export const ProfileContainerComponent = () => {
  const [user, setUser] = useState({ displayName: '', mail: '' });
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [userSkeletonName, setUserSkeletonName] = useState('');
  const [avatar, setAvatar] = useState('');
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  useEffect(() => {
    SUserService.getUser().then((response: any) => {
      setUser(response);
      setUserSkeletonName(response.displayName.match(/\b\w/g).join(''));
      SUserService.getUserDetails().then((userDetailsResponse: any) => {
        setAvatar(userDetailsResponse.avatar);
      });
    });
  });

  const handleLogout = () => {
    SAuthService.logout();
  };
  const handleProfileClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <div className="profile-container">
      <div className="icon-container">
        <IconButton
          style={{ borderRadius: '4px' }}
          aria-describedby="profile-details"
          onClick={handleProfileClick}
        >
          <AvatarComponent
            userName={user?.displayName}
            avatar={avatar}
            width={40}
            height={40}
          />
        </IconButton>
      </div>
      <div className="user-name-container">{user?.displayName}</div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <div>
          <Card className="profile-popover-container">
            <div className="top-container"></div>
            <span className="avatar-upload">
              <span className="avatar-name-container">
                <IconButton
                  style={{ borderRadius: '4px' }}
                  aria-describedby="profile-details"
                >
                  <AvatarComponent
                    userName={user?.displayName}
                    avatar={avatar}
                    width={75}
                    height={75}
                  />
                </IconButton>
              </span>
            </span>
            <span className="name"> {user?.displayName}</span>
            <span className="mail"> {user?.mail} </span>
            <List className="user-details"></List>
            <Divider />
            <List className="actions">
              <ListItem disablePadding>
                <ListItemButton onClick={handleLogout}>
                  <ListItemIcon>
                    <LogoutIcon />
                  </ListItemIcon>
                  <ListItemText className="text">Sign Out</ListItemText>
                </ListItemButton>
              </ListItem>
            </List>
          </Card>
        </div>
      </Popover>
    </div>
  );
};
