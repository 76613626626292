export const addNewUserToProjectMsgTeams = (data: {
  locationName: string;
  locationId: string;
  user: { username: string; password: string };
}) => {
  const message =
    'A new User is added to the location ' +
    data.locationName +
    ' with Location id : ' +
    data.locationId +
    '. ' +
    'The username is ' +
    data.user.username +
    ' and password ' +
    data.user.password;
  return message;
};
