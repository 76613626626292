import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { Info } from '@mui/icons-material';
import ImageIcon from '@mui/icons-material/Image';
import {
  ChangeEvent,
  useEffect,
  useRef,
  useState,
  FunctionComponent,
} from 'react';
import { debounce, matches } from 'lodash';

import { mapUserType } from 'constants/user';
import { NewUserValidator } from 'utils/NewUserValidator';
import SUserDetailsService from 'services/userDetails/userDetails.service';
import { useSnackbar } from 'components/snackbar/SnackbarProvider';
import SLocationService from 'services/location/location.service';
import {
  ILocationDetails,
  ILocationMiniInfo,
} from 'interfaces/location.interface';
import { LocationType } from 'constants/location';
import { TagFilter } from 'components/tagFilter/TagFilter.component';
import { TagComponent } from 'components/Tag/tag.component';
import { ITag } from 'interfaces/tag.interface';
import {
  DomainNames,
  ICreateUserDto,
  IUserDetailsError,
} from 'interfaces/user.interface';
import SUserService from 'services/user/user.service';
import STeamsService from 'services/teams/teams.service';
import { h2DigitalTeamsForNotification } from 'config/authConfig';
import { addNewUserToProjectMsgTeams } from 'utils/teamsMessage';

import './NewUserDialog.scss';
import { findTag } from 'utils/tags';
import { TagKeys, TagValues } from 'constants/tag';

const initialUserDetails = {
  firstName: '',
  lastName: '',
  username: '',
  secondaryEmail: '',
  isAdmin: false,
  isLocked: false,
  isSuperUser: false,
  // TODO: Currently only Provider User is allowed to be created
  userType: mapUserType[1].value,
  teamId: '',
  locationIds: [],
  tagIds: [],
};

interface InputType {
  name: string;
  label: string;
}

interface NewUserDialogProps {
  open: boolean;
  refreshUsersList: () => void;
  handleClose: () => void;
}

export const NewUserDialog: FunctionComponent<NewUserDialogProps> = ({
  open,
  refreshUsersList,
  handleClose,
}: NewUserDialogProps) => {
  const checkBoxes: InputType[] = [
    { name: 'isAdmin', label: 'Admin' },
    { name: 'isSuperUser', label: 'Super User' },
    { name: 'isLocked', label: 'Locked' },
  ];

  const userImageInput = useRef<HTMLInputElement>(null);
  const [userAvatar, setUserAvatar] = useState<Blob>();
  const [isLoading, setIsLoading] = useState(false);
  const [userNameExists, setUserNameExists] = useState<boolean>();
  const [locationList, setLocationList] = useState<ILocationMiniInfo[]>([]);
  const [selectedTags, setSelectedTags] = useState<ITag[]>([]);
  const [selectedLocationId, setSelectedLocationId] = useState<number | null>(
    null
  );
  const [newUserDetails, setNewUserDetails] =
    useState<ICreateUserDto>(initialUserDetails);
  const [userDetailsError, setUserDetailsError] = useState<IUserDetailsError>({
    firstName: '',
    lastName: '',
    username: '',
    secondaryEmail: '',
    userAvatar: '',
  });

  const [location, setLocation] = useState<ILocationDetails>();
  const { showSnackbar } = useSnackbar();

  const handleCheckBoxValueChange = <Key extends keyof ICreateUserDto>(
    property: Key
  ) => {
    setNewUserDetails((prevUser) => ({
      ...prevUser,
      [property]: !prevUser[property],
    }));
  };

  const deleteTags = (id: number) => {
    setSelectedTags(selectedTags.filter((tag) => tag.id !== id));
  };

  const checkUserNameExists = (signal: AbortSignal) => {
    const userid =
      newUserDetails.username?.toLowerCase() + DomainNames.h2Digital;
    const delayedCheck = debounce(async () => {
      const exists = await SUserDetailsService.checkH2DigitalUserExists(
        userid,
        signal
      );
      setUserNameExists(exists.data);
    }, 500);
    delayedCheck();
  };

  useEffect(() => {
    SLocationService.findNames(LocationType.Provider).then((response) => {
      if (response && response.length > 0) {
        setLocationList(response);
      }
    });
  }, []);

  useEffect(() => {
    if (selectedLocationId) {
      //getting teams details from location
      SLocationService.getLocationDetails(selectedLocationId).then((res) => {
        setLocation(res);
      });
    }
  }, [selectedLocationId]);

  useEffect(() => {
    setUserNameExists(undefined);
    const controller = new AbortController();
    const signal = controller.signal;
    if (newUserDetails.username?.trim().length) checkUserNameExists(signal);
    else setUserNameExists(undefined);
    return () => {
      controller.abort();
    };
  }, [newUserDetails.username]);

  const onFieldsChange = async (value: string | number, key: string) => {
    if (newUserDetails) {
      setUserDetailsError({
        ...userDetailsError,
        [key]: '',
      });
      setNewUserDetails({
        ...newUserDetails,
        [key]: value,
      });
    }
  };

  const handleLocationChange = (e: SelectChangeEvent<number | null>) => {
    setSelectedLocationId(e.target.value as number);
  };

  const handleClosePopup = () => {
    setNewUserDetails(initialUserDetails);
    setUserAvatar(undefined);
    setSelectedLocationId(null);
    setSelectedTags([]);
    setUserDetailsError({
      firstName: '',
      lastName: '',
      username: '',
      secondaryEmail: '',
      userAvatar: '',
    });
    handleClose();
  };

  const handleSubmit = () => {
    if (!isLoading && selectedLocationId) {
      setUserDetailsError({
        firstName: '',
        lastName: '',
        username: '',
        secondaryEmail: '',
        userAvatar: '',
      });
      // TODO: Refactor this logic by using ICreateUserDto instead of INewUserDetails
      const errors = NewUserValidator(
        {
          ...newUserDetails,
          username: newUserDetails.username,
        },
        userAvatar
      );
      setUserDetailsError((prev) => {
        return { ...prev, ...errors };
      });
      if (matches(errors)({}) && userAvatar && !userNameExists) {
        setIsLoading(true);
        let providerTag, sourceTag;
        if (location?.tags) {
          providerTag = findTag(selectedTags, TagKeys.Provider, TagValues.OGE);
          sourceTag = findTag(selectedTags, TagKeys.Source, TagValues.NEP);
        }
        newUserDetails.teamId =
          providerTag && sourceTag
            ? process.env.REACT_APP_OGE_CRM_MSTEAMS_ID
            : location?.MSTeamsID;
        newUserDetails.tagIds = selectedTags.map((tag) => tag.id);
        newUserDetails.locationIds = [selectedLocationId];
        SUserService.createUser(newUserDetails)
          .then((res) => {
            if (res) {
              const { aadUser, userDetails } = res;
              const message = addNewUserToProjectMsgTeams({
                locationName: location?.name ?? '',
                locationId: String(location?.id) ?? '',
                user: {
                  username: aadUser.emailAddress,
                  password: aadUser.password,
                },
              });
              STeamsService.sendNotificationInTeams(
                h2DigitalTeamsForNotification.link,
                message
              );
              SUserService.uploadAvatar(userAvatar, userDetails?.id).then(
                () => {
                  refreshUsersList();
                }
              );
              showSnackbar('User creation success', 'success');
              handleClosePopup();
              setIsLoading(false);
            }
          })
          .catch(() => {
            setIsLoading(false);
            showSnackbar('User creation failed', 'error');
            handleClosePopup();
          });
      }
    }
  };

  const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files || event.target.files.length === 0) {
      setUserAvatar(undefined);
      return;
    }
    const file = event.target.files[0];
    setUserDetailsError({
      ...userDetailsError,
      userAvatar: '',
    });
    setUserAvatar(file);
  };

  const handleUserImageClick = () => {
    userImageInput?.current?.click();
  };

  const hasTeamsLinked = location?.MSTeamsID && location?.MSTeamsChannel;

  const showTeamsErrorMessage =
    !hasTeamsLinked && selectedLocationId && location;

  return (
    <Dialog open={open} className="new-user-dialog-team">
      <DialogTitle>Create New Provider User</DialogTitle>{' '}
      <DialogContent>
        {showTeamsErrorMessage && (
          <div className="no-team-msg">
            Team details empty!!! Please use TEAMS tab to add other users to
            teams or to create new user
          </div>
        )}
        <DialogContentText>
          Fill in the form to create a new user
        </DialogContentText>
        <div className="inputs-container">
          <div className="image-container">
            <div className="new-project-image-title">User Image *</div>
            {!userAvatar ? (
              <div className="new-project-image-wrap">
                <ImageIcon
                  className="new-project-image-icon"
                  onClick={handleUserImageClick}
                />
              </div>
            ) : (
              <div className="new-project-image-wrap">
                <img
                  className="new-project-image"
                  src={URL.createObjectURL(userAvatar)}
                  onClick={handleUserImageClick}
                />
              </div>
            )}
            <div className="error-class">
              {userDetailsError?.userAvatar ? 'User Logo cannot be empty' : ''}
            </div>
          </div>
          <input
            type="file"
            ref={userImageInput}
            onChange={handleImageChange}
            style={{ display: 'none' }}
            accept="image/png, image/jpeg"
          />
          <div className="textfield-container">
            <TextField
              rows={2}
              required
              label="First Name"
              className="text-input"
              autoFocus
              error={userDetailsError.firstName ? true : false}
              helperText={userDetailsError.firstName || ''}
              margin="dense"
              type="text"
              variant="standard"
              onChange={(e) => onFieldsChange(e.target.value, 'firstName')}
            />
            <TextField
              rows={2}
              required
              label="Last Name"
              className="text-input"
              autoFocus
              error={userDetailsError.lastName ? true : false}
              helperText={userDetailsError.lastName || ''}
              margin="dense"
              type="text"
              variant="standard"
              onChange={(e) => onFieldsChange(e.target.value, 'lastName')}
            />
            <TextField
              rows={2}
              required
              label="Username"
              className="text-input"
              autoFocus
              error={
                userDetailsError.username ||
                (userNameExists && Boolean(newUserDetails.username))
                  ? true
                  : false
              }
              helperText={
                userDetailsError.username ||
                (userNameExists && Boolean(newUserDetails.username)
                  ? `${newUserDetails.username} already exists`
                  : '')
              }
              margin="dense"
              type="text"
              variant="standard"
              onChange={(e) => onFieldsChange(e.target.value, 'username')}
            />
            <TextField
              rows={2}
              required
              label="Domain"
              className="text-input"
              autoFocus
              value={DomainNames.h2Digital}
              margin="dense"
              type="text"
              variant="standard"
              disabled
            />

            <TextField
              rows={2}
              required
              label="Secondary Email"
              className="text-input"
              autoFocus
              error={userDetailsError.secondaryEmail ? true : false}
              helperText={userDetailsError.secondaryEmail || ''}
              margin="dense"
              type="text"
              variant="standard"
              onChange={(e) => onFieldsChange(e.target.value, 'secondaryEmail')}
            />
            <div className="field-select">
              <FormControl>
                <InputLabel id="user-type-select-label">User Type</InputLabel>
                <Select
                  required
                  variant="outlined"
                  labelId="user-type-select-label"
                  label="User Type"
                  id="demo-simple-select"
                  fullWidth={true}
                  value={newUserDetails.userType}
                  onChange={(e) => onFieldsChange(e.target.value, 'userType')}
                  disabled
                >
                  {mapUserType.map((item) => {
                    return (
                      <MenuItem key={item.value} value={item.value}>
                        {item.label}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
          </div>
        </div>
        <FormGroup className="checkbox-container">
          {checkBoxes.map((checkBox, index) => {
            return (
              <FormControlLabel
                key={index}
                control={
                  <Checkbox
                    value={
                      newUserDetails[checkBox.name as keyof ICreateUserDto]
                    }
                    onChange={() => {
                      handleCheckBoxValueChange(
                        checkBox.name as keyof ICreateUserDto
                      );
                    }}
                  />
                }
                label={checkBox.label}
              />
            );
          })}
        </FormGroup>

        <div className="location-select">
          <FormControl>
            <InputLabel id="location-select-label">
              Provider Location
            </InputLabel>
            <Select
              required
              variant="outlined"
              labelId="location-select-label"
              label="Provider Location"
              id="location-select"
              fullWidth={true}
              value={selectedLocationId}
              onChange={handleLocationChange}
            >
              {locationList.map((item) => {
                return (
                  <MenuItem key={item.id} value={item.id}>
                    {item.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </div>
        <div className="tags-container">
          <div className="tags-title">
            <Typography variant="body1">Add Tags</Typography>
            <Tooltip title="Please add corresponding Provider tag">
              <Info />
            </Tooltip>
          </div>
          <TagFilter
            tags={selectedTags}
            onChange={(tags: ITag[]) => {
              setSelectedTags(tags);
            }}
          />
          <div className="tags-container-pop-up">
            {selectedTags.map((tag: ITag) => {
              return (
                <TagComponent
                  key={tag.id}
                  deleteEnabled={true}
                  deleteAction={deleteTags}
                  tag={tag}
                ></TagComponent>
              );
            })}
          </div>
        </div>
        <DialogActions className="action-btns">
          <Button onClick={handleClosePopup}>Cancel</Button>
          <Button
            variant="contained"
            onClick={handleSubmit}
            disabled={isLoading || !hasTeamsLinked} //not allowing user craetion if team details are empty
          >
            {isLoading ? (
              <CircularProgress size={'20px'}></CircularProgress>
            ) : (
              'Create'
            )}
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};
export default NewUserDialog;
