import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from '@mui/material';
import { CloseOutlined } from '@mui/icons-material';
import { ChangeEvent, FunctionComponent } from 'react';

interface ISearchBarProps {
  searchLabel: string;
  searchQuery: string;
  setSearchQuery: React.Dispatch<React.SetStateAction<string>>;
}

const SearchBar: FunctionComponent<ISearchBarProps> = (
  props: ISearchBarProps
) => {
  const { searchLabel, searchQuery, setSearchQuery } = props;
  const handleSearchQueryChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };
  return (
    <FormControl
      sx={{
        marginTop: '10px',
        marginBottom: '20px',
        marginX: '400px',
        width: '300px',
      }}
    >
      <InputLabel htmlFor="outlined-adornment-search">{searchLabel}</InputLabel>
      <OutlinedInput
        margin="dense"
        label={searchLabel}
        type="text"
        id="outlined-adornment-search"
        value={searchQuery}
        onChange={handleSearchQueryChange}
        endAdornment={
          searchQuery.length ? (
            <InputAdornment position="end">
              <IconButton
                onClick={() => {
                  setSearchQuery('');
                }}
                edge="end"
              >
                <CloseOutlined />
              </IconButton>
            </InputAdornment>
          ) : (
            <></>
          )
        }
      />
    </FormControl>
  );
};

export default SearchBar;
