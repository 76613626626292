import styled from 'styled-components';

const ReportContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const SaveButton = styled.div`
  margin-top: 15px;
`;

const ErrorLabel = styled.p`
  color: red;
`;

const TableWrapper = styled.p`
  height: 400;
  width: '50%';
  margin-top: 20px;
`;

const EmptyList = styled.div`
  margin-bottom: 20px;
  color: red;
`;

export { ReportContainer, SaveButton, ErrorLabel, TableWrapper, EmptyList };
