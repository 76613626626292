import './DriverFunction.scss';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {
  ChangeEvent,
  Dispatch,
  FunctionComponent,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from 'react';
import AddDriverFunctionDialog from './AddDriverFunctionDialog';
import { DriverFunctionData } from 'interfaces/driverFunction.interface';
import SDriverFunctionService from 'services/driverFunction/driverFunction.service';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { useSnackbar } from 'components/snackbar/SnackbarProvider';

const headCells = [
  {
    id: 'type',
    numeric: false,
    disablePadding: true,
    label: 'Type',
  },
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'Name',
  },
];

interface DriverFunctionListInterface {
  list: DriverFunctionData[];
  setRefreshFlag: Dispatch<SetStateAction<boolean>>;
}

const DriverFunctionList: FunctionComponent<DriverFunctionListInterface> = (
  props
) => {
  const { list, setRefreshFlag } = props;
  const [selected, setSelected] = useState<DriverFunctionData[]>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(8);
  const [open, setOpen] = useState(false);
  const [driverFunctionDeleteFlag, setDriverFunctionDeleteFlag] =
    useState(false);

  const handleSelectAllClick = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      if (selected.length) {
        setSelected([]);
      } else {
        setSelected(list);
      }
    } else setSelected([]);
  };

  useEffect(() => {
    setSelected([]);
  }, [list]);

  const handleClick = (row: DriverFunctionData) => {
    const selectedIndex = selected.indexOf(row);
    let newSelected: DriverFunctionData[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, row);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const { showSnackbar } = useSnackbar();

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleEdit = () => setOpen(true);

  const handleDeleteClick = () => {
    setDriverFunctionDeleteFlag(true);
  };

  const handleCloseDelete = () => {
    setDriverFunctionDeleteFlag(false);
  };

  const handleDelete = () => {
    if (selected) {
      const idList: any = selected.map((obj) => obj.id);
      if (idList.length)
        SDriverFunctionService.deleteDriverFunction(idList)
          .then(() => {
            setRefreshFlag(true);
            showSnackbar('Deletion success', 'success');
          })
          .catch(() => {
            showSnackbar('Deletion falied', 'error');
          });
    }
  };

  const isSelected = (row: DriverFunctionData) => selected.indexOf(row) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - list.length) : 0;

  const visibleRows = useMemo(
    () => list.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    [page, rowsPerPage, list]
  );

  return (
    <>
      {open && (
        <AddDriverFunctionDialog
          open={open}
          setOpen={setOpen}
          driverFunctionData={selected[0]}
          setRefreshFlag={setRefreshFlag}
        ></AddDriverFunctionDialog>
      )}
      <Dialog open={driverFunctionDeleteFlag} onClose={handleCloseDelete}>
        <DialogTitle>{'Driver Function Deletion'}</DialogTitle>
        <DialogContent>
          Do you really want to delete these DriverFunctions ?
        </DialogContent>
        <DialogActions sx={{ paddingTop: '50px' }}>
          <div className="button-wrapper">
            <Button onClick={handleCloseDelete}>No</Button>
            <Button onClick={handleDelete} variant="contained">
              Yes
            </Button>
          </div>
        </DialogActions>
      </Dialog>
      <Box sx={{ width: '100%' }}>
        <Paper sx={{ width: '100%', mb: 2 }} className="driver-functions">
          <Toolbar
            sx={{
              pl: { sm: 2 },
              pr: { xs: 1, sm: 1 },
              ...(selected.length > 0 && {
                bgcolor: (theme) =>
                  alpha(
                    theme.palette.primary.main,
                    theme.palette.action.activatedOpacity
                  ),
              }),
            }}
          >
            {selected.length > 0 ? (
              <Typography
                sx={{ flex: '1 1 100%' }}
                color="inherit"
                variant="subtitle1"
                component="div"
              >
                {selected.length} selected
              </Typography>
            ) : (
              <Typography
                sx={{ flex: '1 1 100%' }}
                variant="h6"
                id="tableTitle"
                component="div"
              >
                Driver Functions
              </Typography>
            )}
            {selected.length > 0 && (
              <Tooltip title="Delete">
                <IconButton className="edit-buttons">
                  <DeleteIcon onClick={handleDeleteClick} />
                </IconButton>
              </Tooltip>
            )}
            {selected.length === 1 && (
              <Tooltip title="Edit">
                <IconButton className="edit-buttons">
                  <EditIcon onClick={handleEdit} />
                </IconButton>
              </Tooltip>
            )}
          </Toolbar>
          <TableContainer>
            <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">
                    <Checkbox
                      color="primary"
                      indeterminate={
                        selected.length > 0 && selected.length < list.length
                      }
                      checked={
                        list.length > 0 && selected.length === list.length
                      }
                      onChange={handleSelectAllClick}
                      inputProps={{
                        'aria-label': 'select all desserts',
                      }}
                    />
                  </TableCell>
                  {headCells.map((headCell) => (
                    <TableCell key={headCell.id}>{headCell.label}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {visibleRows.map((row: DriverFunctionData, index: number) => {
                  const isItemSelected = isSelected(row);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow
                      hover
                      onClick={() => handleClick(row)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                      sx={{ cursor: 'pointer' }}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          inputProps={{
                            'aria-labelledby': labelId,
                          }}
                        />
                      </TableCell>
                      <TableCell component="th" id={labelId}>
                        {row.type}
                      </TableCell>
                      <TableCell>{row.name}</TableCell>
                    </TableRow>
                  );
                })}
                {emptyRows > 0 && (
                  <TableRow>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          {list.length > 8 && (
            <TablePagination
              rowsPerPageOptions={[5, 8]}
              component="div"
              count={list.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </Paper>
      </Box>
    </>
  );
};

export default DriverFunctionList;
