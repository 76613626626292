import { FunctionComponent } from 'react';
import './PowerBILoading.component.scss';
import CircularProgress from '@mui/material/CircularProgress';
interface IPowerBILoadingComponent {
  child?: React.ReactNode;
}

const PowerBILoadingComponent: FunctionComponent<IPowerBILoadingComponent> = ({
  child,
}) => {
  return (
    <div
      className='powerbi-loading-component'
      style={{
        backgroundColor: '#f2f8fc',
      }}
    >
      {child || <CircularProgress />}
    </div>
  );
};

export default PowerBILoadingComponent;
