import { commonConstants } from 'constants/common';
import HttpClient from '../httpClient/httpClient';

class DriverFunctionService {
  private azureAdScope = commonConstants.AZURE_AD_SCOPE;

  private httpClient;

  constructor() {
    this.httpClient = new HttpClient(this.azureAdScope).client;
  }

  async getDriverFunctions(locationId: number) {
    const result = await this.httpClient.get(
      `${process.env.REACT_APP_H2_DIGITAL_API_URL}/driver-function/${locationId}`
    );
    return result;
  }

  async createDriverFunction(driverFunctionDetails: any) {
    const result = await this.httpClient.post(
      `${process.env.REACT_APP_H2_DIGITAL_API_URL}/driver-function/add-new-driver`,
      driverFunctionDetails
    );
    return result;
  }

  async updateDriverFunction(locationId: number, driverFunctionDetails: any) {
    const result = await this.httpClient.patch(
      `${process.env.REACT_APP_H2_DIGITAL_API_URL}/driver-function/${locationId}`,
      driverFunctionDetails
    );
    return result;
  }

  async deleteDriverFunction(idList: number[]) {
    const result = await this.httpClient.delete(
      `${process.env.REACT_APP_H2_DIGITAL_API_URL}/driver-function/delete-driver-function`,
      {
        data: idList,
      }
    );
    return result;
  }
}

const SDriverFunctionService = new DriverFunctionService();
export default SDriverFunctionService;
