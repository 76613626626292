export enum AccessType {
  PRIVATE = 'PRIVATE',
  PUBLIC = 'PUBLIC',
}

export enum TagKeys {
  Provider = 'Provider',
  Source = 'Source',
}

export enum TagValues {
  OGE = 'OGE',
  NEP = 'NEP',
}
