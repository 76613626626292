import AuthService from '../auth/auth.service';
import axios from 'axios';

export default class HttpClient {
  private azureAdScope;

  public client;

  constructor(azureAdScope: string) {
    this.azureAdScope = azureAdScope;
    this.client = axios.create();
    this.client.interceptors.request.use(
      (config) => this.addAccessToken(config),
      (error) => Promise.reject(error)
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private async addAccessToken(config: any) {
    const accessToken = await AuthService.getAccessToken(this.azureAdScope);
    if (config?.headers) {
      config.headers.Authorization = 'Bearer ' + accessToken;
    }
    return config;
  }
}
