import { Order } from 'constants/locationTable';
import _ from 'lodash';

export function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  let val1 = b[orderBy],
    val2 = a[orderBy];
  if (_.isString(b[orderBy])) {
    // @ts-ignore
    val1 = _.lowerCase(b[orderBy]);
    // @ts-ignore
    val2 = _.lowerCase(a[orderBy]);
  }
  if (val1 < val2) {
    return -1;
  }
  if (val1 > val2) {
    return 1;
  }
  return 0;
}

export function getComparator<T>(
  order: Order,
  orderBy: keyof T
): (a: T, b: T) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
