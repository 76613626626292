import HttpClient from '../../services/httpClient/httpClient';
import { commonConstants } from 'constants/common';

class GridDataPlanningService {
  private httpClient;

  constructor() {
    this.httpClient = new HttpClient(commonConstants.AZURE_AD_SCOPE).client;
  }

  async addGridDataPlanningRowsNew(locationId: number) {
    try {
      const response = await this.httpClient.post(
        `${process.env.REACT_APP_H2_DIGITAL_API_URL}/griddata-planning/new/${locationId}`,
        locationId
      );

      return response;
    } catch (error) {
      console.log('GridDataPlanning rows load for new location failed', error);
      throw error;
    }
  }
}

const SGridDataPlanningService = new GridDataPlanningService();
export default SGridDataPlanningService;
