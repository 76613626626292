import HttpClient from '../httpClient/httpClient';
import { commonConstants } from 'constants/common';
import { ITag } from 'interfaces/tag.interface';
import { UseQueryResult, useQuery } from 'react-query';

interface FilterTagsParams {
  key?: string;
  value?: string;
}

class TagsService {
  private azureAdScope = commonConstants.AZURE_AD_SCOPE;

  private httpClient;

  private apiEndpoint = process.env.REACT_APP_H2_DIGITAL_API_URL;

  constructor() {
    this.httpClient = new HttpClient(this.azureAdScope).client;
  }

  async getAllTags() {
    return this.httpClient
      .get(`${this.apiEndpoint}/tag`)
      .then((response: { data: any }) => {
        const result = response.data;
        return result;
      });
  }

  useFilterTags(params: FilterTagsParams): UseQueryResult<ITag[]> {
    return useQuery(['tag/filter', params], async ({ signal }) => {
      const result = await this.httpClient.get(
        `${this.apiEndpoint}/tag/filter`,
        {
          params,
          signal,
        }
      );
      return result.data;
    });
  }

  async updateTag(tagDetails: Pick<ITag, 'accessType'>, tagId: number) {
    const response = await this.httpClient.patch(
      `${this.apiEndpoint}/tag/${tagId}`,
      tagDetails
    );
    return response;
  }
}
const STagsService = new TagsService();
export default STagsService;
