import { XAxis, YAxis, Tooltip, AreaChart, Area } from 'recharts';
import { FunctionComponent } from 'react';
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const AnalyticsGraph: FunctionComponent<{ data: any; color: string }> = (
  props
) => {
  const { data, color } = props;

  return (
    <AreaChart width={250} height={100} data={data}>
      <XAxis dataKey="name" hide />
      <YAxis hide />
      <Tooltip />
      <Area type="monotone" dataKey="value" stroke={color} fill={color} />
    </AreaChart>
  );
};
