import React from 'react';

export const Map = () => {
  return (
    <div>
      Test the embedded map here.
      <iframe
        title="H2Digital"
        src={`${process.env.REACT_APP_H2_DIGITAL_URL}/embed-map`}
        width="100%"
        height="500vh"
      />
    </div>
  );
};
