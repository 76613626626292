import React from 'react';
import { AuthorizationWrapperComponent } from './components/AuthorizationWrapper/AuthorizationWrapper.component';
import { PageContainerComponent } from './pages/PageContainer.component';
import { BrowserRouter } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import { FallBack } from './pages/Fallback/fallback.component';
import { SnackbarProvider } from 'components/snackbar/SnackbarProvider';

const errorHandler = (err: Error, errInfo: { componentStack: string }) => {
  console.log('Logging error: ', err, errInfo);
};

const App: React.FunctionComponent = () => {
  return (
    <AuthorizationWrapperComponent>
      <BrowserRouter>
        <ErrorBoundary FallbackComponent={FallBack} onError={errorHandler}>
          <SnackbarProvider>
            <PageContainerComponent></PageContainerComponent>
          </SnackbarProvider>
        </ErrorBoundary>
      </BrowserRouter>
    </AuthorizationWrapperComponent>
  );
};

export default App;
