import { FunctionComponent, useState } from 'react';

import ProjectList from './Project-list';
import { DataLoad } from './DataLoad';

import './Project.scss';
import ProjectAnalytics from './Project-Analytics';

const Project: FunctionComponent = () => {
  const [refresh, setRefresh] = useState(true);

  return (
    <div className="project-main-container">
      <div className="project-overview-header-container">
        <div className="project-details-title"> Project Details</div>
      </div>
      <ProjectAnalytics />
      <ProjectList refresh={refresh} setRefresh={setRefresh}></ProjectList>
      <DataLoad />
    </div>
  );
};

export default Project;
