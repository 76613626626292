import React, { useEffect, useMemo, useState } from 'react';
import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { ITag } from 'interfaces/tag.interface';
import { LoadingComponent } from '../../components/loading/Loading.component';
import SearchBar from '../../components/SearchBar/SearchBar.component';
import { TagContainer } from 'pages/Tags/style';
import { TableWrapper } from 'pages/Reports/style';
import EditTag from './EditTag';
import AddUsers from './AddUsers';
import { useSnackbar } from 'components/snackbar/SnackbarProvider';
import STagsService from 'services/tags/tags.service';
import { debounce } from 'lodash';
import { Edit, PersonAdd } from '@mui/icons-material';

export const SearchTags: React.FunctionComponent = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [tagsList, setTagsList] = useState<ITag[]>([]);
  const [filteredTagsList, setFilteredTagsList] = useState<ITag[]>([]);
  const [tagsLoading, setTagsLoading] = useState(false);

  const [isEditOpen, setIsEditOpen] = React.useState(false);
  const [isAddUsersOpen, setIsAddUsersOpen] = React.useState(false);
  const [selectedTag, setSelectedTag] = useState<ITag | null>(null);

  const { showSnackbar } = useSnackbar();

  const fetchAllTags = () => {
    setTagsLoading(true);
    STagsService.getAllTags()
      .then((response) => {
        setTagsList(response);
        setTagsLoading(false);
      })
      .catch((error) => {
        setTagsLoading(false);
        console.error(error);
      });
  };

  useEffect(() => {
    fetchAllTags();
  }, []);

  // Memoize the debounced function to prevent it from being recreated on every render
  const debouncedFilterTags = useMemo(() => {
    return debounce((query, list) => {
      const lowerCaseQuery = query?.toLowerCase();
      const filteredTags = list.filter((tag: ITag) => {
        const { key, value } = tag;
        return (
          key?.toLowerCase().includes(lowerCaseQuery) ||
          value?.toLowerCase().includes(lowerCaseQuery)
        );
      });
      setFilteredTagsList(filteredTags);
    }, 250);
  }, []);

  useEffect(() => {
    debouncedFilterTags(searchQuery, tagsList);

    // Cleanup function to cancel the debounced function call if searchQuery or tagsList changes
    return () => {
      debouncedFilterTags.cancel();
    };
  }, [searchQuery, tagsList, debouncedFilterTags]);

  const handleOnEditClick = (tag: ITag) => {
    setSelectedTag(tag);
    setIsEditOpen(true);
  };

  const handleOnAddUsersClick = (tag: ITag) => {
    setSelectedTag(tag);
    setIsAddUsersOpen(true);
  };

  const handleEditTag = (tag: ITag) => {
    if (tag) {
      const { id, accessType } = tag;
      STagsService.updateTag({ accessType }, id)
        .then(() => {
          fetchAllTags();
          setIsEditOpen(false);
          showSnackbar('Tag updated successfully!', 'success');
        })
        .catch(() => {
          setIsEditOpen(false);
          showSnackbar('Update Tag Failed!', 'error');
        });
    }
  };

  return (
    <TagContainer>
      {tagsLoading ? (
        <div style={{ marginBottom: '20px' }}>
          <LoadingComponent />
        </div>
      ) : (
        <TableWrapper>
          <SearchBar
            searchLabel="Search Tags"
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
          />
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }}>
              <TableHead>
                <TableRow selected>
                  <TableCell align="left">Key</TableCell>
                  <TableCell align="left">Value</TableCell>
                  <TableCell align="left">AccessType</TableCell>
                  <TableCell align="center">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredTagsList.map((tag: ITag) => (
                  <TableRow
                    key={tag.id}
                    sx={{
                      '&:last-child td, &:last-child th': { border: 0 },
                    }}
                  >
                    <TableCell sx={{ maxWidth: 300 }} align="left">
                      {tag.key}
                    </TableCell>
                    <TableCell sx={{ maxWidth: 300 }} align="left">
                      {tag.value}
                    </TableCell>
                    <TableCell align="left">{tag.accessType}</TableCell>
                    <TableCell align="center">
                      <IconButton
                        onClick={() => {
                          handleOnEditClick(tag);
                        }}
                      >
                        <Edit color="info" />
                      </IconButton>
                      <IconButton
                        onClick={() => {
                          handleOnAddUsersClick(tag);
                        }}
                      >
                        <PersonAdd color="secondary" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {selectedTag && isEditOpen && (
            <EditTag
              open={isEditOpen}
              setOpen={setIsEditOpen}
              handleEditTag={handleEditTag}
              selectedTag={selectedTag}
              setSelectedTag={setSelectedTag}
            />
          )}
          {selectedTag && isAddUsersOpen && (
            <AddUsers
              open={isAddUsersOpen}
              setOpen={setIsAddUsersOpen}
              selectedTag={selectedTag}
            />
          )}
        </TableWrapper>
      )}
    </TagContainer>
  );
};
