import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  TextField,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { SyntheticEvent, useCallback, useEffect, useState } from 'react';
import { AxiosError } from 'axios';

import { ITag } from 'interfaces/tag.interface';
import { IGetUser } from 'interfaces/user.interface';
import SUserService from '../../services/user/user.service';
import { useSnackbar } from '../../components/snackbar/SnackbarProvider';

interface IAddUsersProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  selectedTag: ITag;
}

const AddUsers = (props: IAddUsersProps) => {
  const { open, setOpen, selectedTag } = props;
  const [isEdited, setIsEdited] = useState(false);
  const [userList, setUserList] = useState<IGetUser[]>([]);
  const [filteredUserList, setFilteredUserList] = useState<IGetUser[]>([]);
  const [selectedUserIds, setSelectedUserIds] = useState<number[]>([]);
  const [searchQuery, setSearchQuery] = useState('');

  const { showSnackbar } = useSnackbar();

  const handleUserSelect = (
    e: SyntheticEvent<Element, Event>,
    user: IGetUser | null
  ) => {
    if (user) {
      setSelectedUserIds((prevSelectedUserIds) => {
        if (!prevSelectedUserIds.includes(user.id)) {
          return [...prevSelectedUserIds, user.id];
        }
        return prevSelectedUserIds;
      });
      setSearchQuery('');
      setIsEdited(true);
    }
  };

  const handleDeleteUserSelection = (userId: number) => {
    setSelectedUserIds((prevSelectedUserIds) =>
      prevSelectedUserIds.filter((id) => id !== userId)
    );
    setIsEdited(true);
  };

  const handleClose = () => {
    setOpen(false);
    setIsEdited(false);
  };

  const getAllUsers = () => {
    SUserService.getAllUserNames()
      .then((response) => {
        setUserList(response);
      })
      .catch((error: AxiosError) => {
        console.error(error);
      });
  };

  const getTagWithUsers = useCallback(() => {
    SUserService.getUsersWithTag(selectedTag.id).then((response) => {
      setSelectedUserIds(response.map((user: IGetUser) => user.id));
    });
  }, [selectedTag]);

  const handleAddUsers = () => {
    SUserService.linkUsersToTag(selectedTag.id, selectedUserIds)
      .then(() => {
        showSnackbar('Users linked to tag successfully!', 'success');
        setOpen(false);
      })
      .catch(() => {
        showSnackbar('Linking users to tag failed!', 'error');
        setOpen(false);
      });
  };

  useEffect(() => {
    getAllUsers();
  }, []);

  useEffect(() => {
    getTagWithUsers();
  }, [selectedTag]);

  useEffect(() => {
    if (searchQuery === '') {
      setFilteredUserList(userList);
    } else {
      const filteredList = userList.filter((user) =>
        user.emailAddress.toLowerCase().includes(searchQuery.toLowerCase())
      );
      setFilteredUserList(filteredList);
    }
  }, [searchQuery, userList]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="xs"
      fullWidth
    >
      <DialogTitle id="alert-dialog-title">Link Users to Tag</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={6} sx={{ mb: 3, mt: 1 }}>
            <FormControl fullWidth>
              <TextField
                size="medium"
                label="Key"
                type="string"
                value={selectedTag.key}
                disabled
              />
            </FormControl>
          </Grid>
          <Grid item xs={6} sx={{ mb: 3, mt: 1 }}>
            <FormControl fullWidth>
              <TextField
                size="medium"
                label="Value"
                type="string"
                value={selectedTag.value}
                disabled
              />
            </FormControl>
          </Grid>
        </Grid>
        <FormControl fullWidth sx={{ mb: 2 }}>
          <TextField
            size="medium"
            label="Access Type"
            type="string"
            value={selectedTag.accessType}
            disabled
          />
        </FormControl>
        <FormControl fullWidth>
          <Autocomplete
            id="user-autocomplete"
            options={filteredUserList}
            getOptionLabel={(user) => user.emailAddress}
            onChange={handleUserSelect}
            value={null}
            inputValue={searchQuery}
            onInputChange={(_, value) => {
              setSearchQuery(value);
            }}
            isOptionEqualToValue={(option, value) =>
              option.emailAddress === value.emailAddress
            }
            renderOption={(prop, option) => {
              return (
                <MenuItem
                  {...prop}
                  key={option.id}
                  value={option.emailAddress}
                  selected={selectedUserIds.includes(option.id)}
                >
                  <div>{option.emailAddress}</div>
                </MenuItem>
              );
            }}
            renderInput={(params) => (
              <TextField {...params} label="Select User" />
            )}
          />
        </FormControl>
        <List
          sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
        >
          {selectedUserIds.map((userId) => {
            const user = userList.find((item) => item.id === userId);
            return (
              <ListItem
                key={user?.id}
                secondaryAction={
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={() => {
                      handleDeleteUserSelection(userId);
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                }
              >
                <ListItemText primary={user?.emailAddress} />
              </ListItem>
            );
          })}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button
          color="success"
          disabled={!isEdited}
          onClick={handleAddUsers}
          autoFocus
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddUsers;
