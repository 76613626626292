import styled from 'styled-components';

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const HeaderComponent = () => {
  return (
    <HeaderWrapper>
      <h2 style={{ color: '#5F9CD9' }}>Admin Center</h2>
    </HeaderWrapper>
  );
};
