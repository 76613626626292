/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useState, createContext, useContext, ReactNode } from 'react';
import AlertComponent from 'components/alerts/Alert.component';
import SnackbarComponent from './Snackbar.component';

// Create a context for the Snackbar
const SnackbarContext = createContext({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  showSnackbar: (message: string, severity?: Severity) => {},
  hideSnackbar: () => {},
});

interface SnackbarProviderProps {
  children: ReactNode;
}

type Severity = 'success' | 'warning' | 'info' | 'error';

function SnackbarProvider({ children }: SnackbarProviderProps) {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [severity, setSeverity] = useState<Severity>('info');

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const showSnackbar = (message: string, severity?: Severity) => {
    if (severity) setSeverity(severity);
    setMessage(message);
    setOpen(true);
  };

  const hideSnackbar = () => {
    setOpen(false);
  };

  return (
    <SnackbarContext.Provider value={{ showSnackbar, hideSnackbar }}>
      {children}
      <SnackbarComponent open={open} onClose={hideSnackbar}>
        <AlertComponent
          onClose={hideSnackbar}
          severity={severity}
          message={message}
          variant="filled"
        />
      </SnackbarComponent>
    </SnackbarContext.Provider>
  );
}

// Custom hook to use the Snackbar context
function useSnackbar() {
  return useContext(SnackbarContext);
}

export { SnackbarProvider, useSnackbar };
