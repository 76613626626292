import HttpClient from '../httpClient/httpClient';
import { commonConstants } from 'constants/common';

const BASE_URL = process.env.REACT_APP_H2_DIGITAL_API_URL;

class PowerBIService {
  /* unlike other services PowerBIService entries are returning
  UseQueryResult, not result itself. See example in usages of the entries. */

  private httpClient;

  constructor() {
    try {
      this.httpClient = new HttpClient(commonConstants.AZURE_AD_SCOPE).client;
      console.log('HttpClient initialized successfully');
    } catch (error) {
      console.error('Error initializing HttpClient', error);
    }
  }

  async getSupportDashboard() {
    console.log('inside support service');
    try {
      if (this.httpClient) {
        const response = await this.httpClient.get(
          `${BASE_URL}/power-bi/support-dashboard/`
        );
        const result = response.data;
        return result;
      }
    } catch (err) {
      console.log('Support report fetch failed', err);
    }
  }
}
const SPowerBIService = new PowerBIService();
export default SPowerBIService;
