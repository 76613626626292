import { ILocationTypeMap } from 'interfaces/location.interface';

export enum LocationType {
  Provider = 10,
  Project = 20,
}

export enum H2DigitalLicense {
  FREE = 10,
  PROFESSIONAL = 20,
  PREMIUM = 30,
}

export const locationTypeMap: ILocationTypeMap[] = [
  { value: LocationType.Provider, label: 'Provider' },
];
