import { ITag } from 'interfaces/tag.interface';

export const findTag = (
  tags: ITag[],
  key: string,
  value?: string
): ITag | undefined => {
  if (value)
    return tags?.find(
      (tag) =>
        tag.key.toLowerCase().trim() === key.toLowerCase().trim() &&
        tag.value.toLowerCase().trim() === value.toLowerCase().trim()
    );
  else
    return tags?.find(
      (tag) => tag.key.toLowerCase().trim() === key.toLowerCase().trim()
    );
};
