import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from '@mui/material';
import { AccessType } from 'constants/tag';
import { ITag } from 'interfaces/tag.interface';
import { useState } from 'react';

interface IEditTagProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  handleEditTag: (tag: ITag) => void;
  selectedTag: ITag;
  setSelectedTag: (tag: ITag) => void;
}

const EditTag = (props: IEditTagProps) => {
  const { open, setOpen, handleEditTag, selectedTag, setSelectedTag } = props;
  const [isEdited, setIsEdited] = useState(false);
  const accessTypes = Object.keys(AccessType);
  const [newTag, setNewTag] = useState<ITag>(selectedTag);
  const onAccessTypeChange = (e: SelectChangeEvent<string>) => {
    const accessType = e.target.value as AccessType;
    setNewTag({ ...newTag, accessType: accessType });
    setIsEdited((prev) => !prev);
  };
  const handleClose = () => {
    setOpen(false);
    setIsEdited(false);
  };
  const handleConfirm = () => {
    setSelectedTag(newTag);
    handleEditTag(newTag);
    handleClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="xs"
      fullWidth
    >
      <DialogTitle id="alert-dialog-title">Edit Tag</DialogTitle>
      <DialogContent>
        <FormControl fullWidth sx={{ mb: 3, mt: 2 }}>
          <TextField
            size="medium"
            label="Key"
            type="string"
            value={newTag.key}
            disabled
          />
        </FormControl>
        <FormControl fullWidth sx={{ mb: 3 }}>
          <TextField
            size="medium"
            label="Value"
            type="string"
            value={newTag.value}
            disabled
          />
        </FormControl>
        <FormControl required fullWidth sx={{ mb: 2 }}>
          <InputLabel id="access-type-select-label">Access Type</InputLabel>
          <Select
            labelId="access-type-label"
            id="access-type-select"
            size="medium"
            label="Access Type"
            value={newTag.accessType}
            onChange={onAccessTypeChange}
          >
            {accessTypes?.map((type: string, index: number) => {
              return (
                <MenuItem key={index} value={type}>
                  <div>{type}</div>
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button
          color="success"
          disabled={!isEdited}
          onClick={handleConfirm}
          autoFocus
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditTag;
