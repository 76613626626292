import {
  Autocomplete,
  Button,
  CircularProgress,
  FormControl,
  TextField,
  Typography,
} from '@mui/material';
import React, { SyntheticEvent, useCallback, useEffect, useState } from 'react';
import './Users.scss';
import SUserService from '../../services/user/user.service';
import { IUserDetails } from '../../interfaces/user.interface';
import LocationAutocomplete from '../../components/LocationAutocomplete/LocationAutocomplete';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PersonPinIcon from '@mui/icons-material/PersonPin';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import FactoryIcon from '@mui/icons-material/Factory';
import { InitialUserDetails } from '../../constants/user';
import { debounce, omit } from 'lodash';
import { AxiosError } from 'axios';
import { useSnackbar } from 'components/snackbar/SnackbarProvider';
import { emailExpression } from 'constants/common';
import { ListUsers } from './components/ListUsers';
import { EditUserDetails } from './components/EditUserDetails';
import { ILocationMiniInfo } from 'interfaces/location.interface';
import SLocationService from '../../services/location/location.service';
import NewUserDialog from './components/NewUserDialog';

interface AADUserType {
  id: string;
  userPrincipalName: string;
  otherMails: string[];
}

const Users: React.FunctionComponent = () => {
  const [selectedLocation, setSelectedLocation] =
    useState<ILocationMiniInfo | null>(null);
  const [locationList, setLocationList] = useState<ILocationMiniInfo[]>([]);
  const [userLocation, setUserLocation] = useState<ILocationMiniInfo | null>();
  const [AADUsers, setAADUsers] = useState<AADUserType[]>([]);
  const [usersList, setUsersList] = useState<IUserDetails[]>([]);
  const [usersLoading, setUsersLoading] = useState(false);
  const [newUserDialogue, setNewUserDialogue] = useState(false);
  const [editUserDialogue, setEditUserDialogue] = useState(false);
  const [emailError, setEmailError] = useState('');
  const [selectedUser, setSelectedUser] =
    useState<Partial<IUserDetails>>(InitialUserDetails);
  const [isLoading, setIsLoading] = useState(false);
  const [isUserAADOnly, setIsUserAADOnly] = useState(false);
  const [selectedAADUser, setSelectedAADUser] = useState<AADUserType>();
  const [searchQuery, setSearchQuery] = useState('');
  const [tabValue, setTabValue] = useState(0);
  const { showSnackbar } = useSnackbar();

  const fetchUsersOfLocation = (locationId: number) => {
    SUserService.getUsersByLocationId(locationId)
      .then((response) => {
        setUsersList(response);
        setUsersLoading(false);
      })
      .catch((error) => {
        setUsersLoading(false);
        console.error(error);
      });
  };

  useEffect(() => {
    SLocationService.findNames()
      .then((response) => {
        setLocationList(response);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const handleLocationSelect = (
    e: SyntheticEvent<Element, Event>,
    location: ILocationMiniInfo | null
  ) => {
    setUsersLoading(true);
    setUserLocation(location);
    setSelectedLocation(location);
    if (location?.id) {
      fetchUsersOfLocation(location?.id);
    }
  };

  const handleCloseEdit = () => {
    setEditUserDialogue(false);
  };

  const handleUpdateUserDetails = () => {
    if (selectedUser.id && selectedUser.secondaryEmail) {
      const validEmail = emailExpression.test(selectedUser.secondaryEmail);
      if (validEmail) {
        const userData = omit(selectedUser, 'id', 'locations');
        SUserService.updateUserDetails(userData, selectedUser.id)
          .then(() => {
            handleCloseEdit();
            if (selectedLocation?.id) fetchUsersOfLocation(selectedLocation.id);
            showSnackbar('User details updated successfully!', 'success');
          })
          .catch((error) => {
            console.error(error);
            showSnackbar('User details update failed!', 'error');
          });
      } else {
        setEmailError('Please enter a valid email!');
      }
    } else {
      setEmailError('Secondary Email cannot be empty!!!');
    }
  };

  const handleEditUser = (user: IUserDetails) => {
    setSelectedUser(user);
    setUserLocation(user?.locations?.[0]);
    setEditUserDialogue(true);
  };

  const resetPage = () => {
    setIsUserAADOnly(false);
    setSelectedUser(InitialUserDetails);
    setUserLocation(undefined);
    setSelectedLocation(null);
    setSelectedAADUser(undefined);
    setUsersList([]);
  };

  const handleTabChange = (e: React.SyntheticEvent, tab: number) => {
    setTabValue(tab);
    resetPage();
  };

  const getUserDetailsByAADId = (AADUserId: string) => {
    SUserService.getUserWithLocationsByAadId(AADUserId).then((user) => {
      if (user) {
        setIsUserAADOnly(false);
        handleEditUser(user);
      } else {
        setIsUserAADOnly(true);
      }
    });
  };

  const handleAADUserSelect = (
    e: SyntheticEvent<Element, Event>,
    AADUser: AADUserType | null
  ) => {
    if (AADUser) {
      setSelectedAADUser(AADUser);
      getUserDetailsByAADId(AADUser.id);
    }
  };

  const handleCreateUserInDB = async () => {
    if (selectedAADUser) {
      const userData = {
        emailAddress: selectedAADUser.userPrincipalName, //get email from AAD,
        isAdmin: false,
        isLocked: false,
        isSuperUser: false,
        aadObjectId: selectedAADUser.id,
      };
      await SUserService.addUserDetails(userData);
      getUserDetailsByAADId(selectedAADUser.id);
    }
  };

  //  Fetch aad users based on user input.
  const getAADUsers = useCallback(
    debounce(
      (
        query: string,
        signal: AbortSignal,
        callback: (options: any) => void
      ) => {
        setAADUsers([]);
        SUserService.getAADUsers(query, signal)
          .then(callback)
          .catch((err: Error | AxiosError) => {
            if (err.name !== 'CanceledError') {
              alert(err.message);
            }
          });
      },
      250
    ), // optimal debounce Time for a good UX
    []
  );

  useEffect(() => {
    if (tabValue === 1) {
      setIsLoading(true);
      const controller = new AbortController();
      const signal = controller.signal;
      getAADUsers(searchQuery, signal, (users: { data: AADUserType[] }) => {
        setAADUsers(users.data);
        setIsLoading(false);
      });

      return () => {
        controller.abort();
      };
    }
  }, [searchQuery, getAADUsers, tabValue]);

  return (
    <div className="user-main-container">
      <div className="user-overview-header-container">
        <Typography variant="h4">Users</Typography>
        <Button
          variant="outlined"
          onClick={() => setNewUserDialogue(true)}
          endIcon={<PersonAddIcon />}
        >
          Create User
        </Button>
      </div>
      <Tabs value={tabValue} onChange={handleTabChange}>
        <Tab icon={<FactoryIcon />} label="BY PROJECTS" />
        <Tab icon={<PersonPinIcon />} label="FROM AAD" />
      </Tabs>
      {tabValue === 0 ? (
        <>
          <div className="location-select-user">
            <FormControl required sx={{ mb: 5 }}>
              {locationList?.length ? (
                <LocationAutocomplete
                  locationList={locationList}
                  onSelectedLocationChange={handleLocationSelect}
                  value={selectedLocation}
                />
              ) : (
                <CircularProgress size="30px" />
              )}
            </FormControl>
          </div>
          {selectedLocation && (
            <ListUsers
              loading={usersLoading}
              usersList={usersList}
              onUserEdit={handleEditUser}
              refreshUsersList={() => {
                fetchUsersOfLocation(selectedLocation.id);
              }}
            />
          )}
        </>
      ) : (
        <>
          <div className="aad-users-container">
            <Autocomplete
              id="user-autocomplete"
              options={AADUsers}
              loading={isLoading}
              getOptionLabel={(user) => user.userPrincipalName}
              onChange={handleAADUserSelect}
              value={selectedAADUser || null}
              inputValue={searchQuery}
              onInputChange={(event, newInputValue) => {
                setSearchQuery(newInputValue);
              }}
              selectOnFocus
              isOptionEqualToValue={(option, value) =>
                option.userPrincipalName === value.userPrincipalName
              }
              renderInput={(params) => (
                <TextField {...params} label="Select User" />
              )}
            />
          </div>
          {isUserAADOnly && (
            <>
              <div className="no-location-msg">
                The selected user has no entry in the database.
              </div>
              <div>
                <div className="add-location-btn">
                  <Button
                    sx={{
                      backgroundColor: '#234C8A',
                      color: '#ffff',
                      fontFamily: 'Inria Sans',
                      '&:hover': {
                        backgroundColor: '#ffff',
                        color: '#234c8a',
                      },
                    }}
                    variant="outlined"
                    onClick={handleCreateUserInDB}
                  >
                    Add User
                  </Button>
                </div>
              </div>
            </>
          )}
        </>
      )}
      <NewUserDialog
        open={newUserDialogue}
        handleClose={() => setNewUserDialogue(false)}
        refreshUsersList={() => {
          if (selectedLocation) {
            fetchUsersOfLocation(selectedLocation?.id);
          }
        }}
      />
      <EditUserDetails
        editUserDialogue={editUserDialogue}
        handleCloseEdit={handleCloseEdit}
        selectedUser={selectedUser}
        setSelectedUser={setSelectedUser}
        emailError={emailError}
        setEmailError={setEmailError}
        locationsList={locationList}
        userLocation={userLocation ?? undefined}
        setUserLocation={setUserLocation}
        handleUpdateUserDetails={handleUpdateUserDetails}
      />
    </div>
  );
};

export default Users;
