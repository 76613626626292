/* eslint-disable @typescript-eslint/no-explicit-any */
/*******************************************************
Copyright (C) 2024 H2 Digital GmbH - info@h2-digital.com , Inc - All Rights Reserved
Proprietary and confidential.
Unauthorized copying of this file, via any medium is strictly prohibited.
Written by Darshan Sannamuddaiah, darshan.sannamuddaiah@h2-digital.com, December 2022
*******************************************************/
import {
  Autocomplete,
  AutocompleteRenderInputParams,
  Box,
  TextField,
  Typography,
} from '@mui/material';
import SLocationService from '../../services/location/location.service';
import { debounce } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { AxiosError } from 'axios';
import { Address } from 'interfaces/location.interface';
type Variant = 'standard' | 'filled' | 'outlined' | undefined;
interface AddressAutocompleteProps {
  defaultValue: string;
  helperText?: string;
  handleFormChange: (event: Address) => void;
  isRequired: boolean;
  variant?: Variant;
}

const AddressAutocomplete: React.FC<AddressAutocompleteProps> = ({
  defaultValue,
  helperText,
  handleFormChange,
  isRequired,
  variant = 'standard',
}) => {
  const [addresses, setAddresses] = useState<Address[]>([]);
  const [currentAddress, setCurrentAddress] = useState<Address | null>(null);
  const [searchQuery, setSearchQuery] = useState<string>(defaultValue || '');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  //  Fetch address suggestions based on user input.
  const getAddressSuggestions = useCallback(
    debounce(
      (
        query: string,
        signal: AbortSignal,
        callback: (options: any) => void
      ) => {
        setAddresses([]);
        if (query !== '') {
          SLocationService.autoComplete(query, signal)
            .then(callback)
            .catch((err: Error | AxiosError) => {
              if (err.name !== 'CanceledError') {
                alert(err.message);
              }
            });
        }
      },
      250
    ), // optimal debounce Time for a good UX
    []
  );

  useEffect(() => {
    setIsLoading(true);
    const controller = new AbortController();
    const signal = controller.signal;
    getAddressSuggestions(searchQuery, signal, (options) => {
      setAddresses(options.data);
      setIsLoading(false);
    });
    return () => {
      controller.abort();
    };
  }, [searchQuery, getAddressSuggestions]);

  const onChange = (
    event: React.SyntheticEvent,
    selectedAddress: any | null
  ) => {
    if (selectedAddress?.freeformAddress) {
      handleFormChange({
        freeformAddress: selectedAddress?.freeformAddress,
        lat: selectedAddress?.lat || '',
        lng: selectedAddress?.lng || '',
      });
      setCurrentAddress(selectedAddress);
    }
  };

  const onInputChange = (event: React.SyntheticEvent, searchText: string) => {
    setSearchQuery(searchText);
  };

  const renderInput = (
    params: AutocompleteRenderInputParams
  ): React.ReactNode => {
    return (
      <TextField
        {...params}
        required={isRequired}
        helperText={helperText}
        fullWidth
        autoFocus
        margin="dense"
        id="address"
        label="Address"
        type="text"
        variant={variant}
        name="address"
        value={currentAddress}
        inputProps={{
          ...params.inputProps,
          autoComplete: 'new-password', // To disable browser default autocomplete and autofill
        }}
        error={Boolean(helperText)}
      />
    );
  };

  //  To render the dropdown
  const renderOption = (
    props: React.HTMLAttributes<HTMLLIElement>,
    option: Address
  ): React.ReactNode => {
    return (
      <Box component="li" {...props} key={option.id}>
        <Typography variant="body2" color="text.secondary">
          {option.freeformAddress}
        </Typography>
      </Box>
    );
  };

  return (
    <Autocomplete
      options={addresses}
      value={currentAddress}
      loading={isLoading}
      onChange={onChange}
      onInputChange={onInputChange}
      getOptionLabel={(selected) => {
        return selected.freeformAddress;
      }}
      isOptionEqualToValue={(option, value) =>
        option.freeformAddress === value.freeformAddress
      }
      renderInput={renderInput}
      renderOption={renderOption}
      filterOptions={(suggestions) => suggestions}
      blurOnSelect={true}
    />
  );
};

export default AddressAutocomplete;
