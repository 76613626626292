import expandMoreIcon from 'assets/svg/arrowExpandMore.svg';
import './SelectArrow.component.scss';

interface ISelectArrowProps {
  className?: string;
}

export const SelectArrow = (props: ISelectArrowProps) => {
  return (
    <img
      className={`select-arrow-icon ${props.className ?? ''}`}
      src={expandMoreIcon}
    />
  );
};
