// import axios from 'axios';
import { commonConstants } from 'constants/common';
import HttpClient from '../httpClient/httpClient';
// import FormData from 'form-data';

class ProjectBulkUploadService {
  private azureAdScope = commonConstants.AZURE_AD_SCOPE;

  private httpClient;

  constructor() {
    this.httpClient = new HttpClient(this.azureAdScope).client;
  }

  async flushAndLoadH2DigitalProjects() {
    // TODO: can use this logic once cors issue is fixed
    // const url =
    //   '<H2 Digital Blob storage>/ProjectBulkUpload.xlsx';
    // const xlsxBuffer = await axios.get(url, { responseType: 'blob' });

    // // Create a FormData object and append the XLSX file
    // const formData = new FormData();
    // formData.append('file', xlsxBuffer?.data, 'ProjectBulkUpload.xlsx');
    return this.httpClient
      .post(
        `${process.env.REACT_APP_H2_DIGITAL_API_URL}/project-bulk-upload/data-load`,
        {},
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          responseType: 'blob',
        }
      )
      .then(async (response) => {
        const blob = await response.data;

        // Create a Blob URL for the file
        const downloadUrl = window.URL.createObjectURL(blob);

        // Create a link element and trigger a click on it to start the download
        const a = document.createElement('a');
        a.href = downloadUrl;
        a.download = 'output.xlsx';
        a.click();

        // Release the object URL to free up resources
        window.URL.revokeObjectURL(downloadUrl);
        return true;
      })
      .catch((err) => {
        return err;
      });
  }
}
const SProjectBulkUploadService = new ProjectBulkUploadService();
export default SProjectBulkUploadService;
