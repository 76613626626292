import React, { FunctionComponent } from 'react';
import { Alert, AlertTitle } from '@mui/material';

interface AlrtProps {
  message: string;
  onClose?: () => void;
  severity: 'success' | 'warning' | 'info' | 'error';
  title?: string;
  variant?: 'filled' | 'outlined' | 'standard';
}

const AlertComponent: FunctionComponent<AlrtProps> = ({
  message,
  onClose,
  severity,
  title,
  variant,
}) => {
  return (
    <Alert severity={severity} onClose={onClose} variant={variant}>
      <AlertTitle>{title}</AlertTitle>
      {message}
    </Alert>
  );
};
export default AlertComponent;
