import { commonConstants } from 'constants/common';
import HttpClient from '../httpClient/httpClient';

class UserDetailsService {
  private userDetails = {};

  private apiUrl = process.env.REACT_APP_H2_DIGITAL_API_URL;

  private azureAdScope = commonConstants.AZURE_AD_SCOPE;

  private httpClient;

  constructor() {
    this.httpClient = new HttpClient(this.azureAdScope).client;
  }

  private getUserDetailsFromApi() {
    return this.httpClient.get(this.apiUrl + '/user-details');
  }

  getUserDetails(): Promise<object> {
    return new Promise((resolve, reject) => {
      if (Object.keys(this.userDetails).length) {
        resolve(this.userDetails);
      } else {
        this.getUserDetailsFromApi()
          .then(({ data }: { data: any }) => {
            this.userDetails = data;
            resolve(this.userDetails);
          })
          .catch((err: Error) => {
            reject(err);
          });
      }
    });
  }

  async checkH2DigitalUserExists(email: string, signal: AbortSignal) {
    const result = await this.httpClient.get(
      `${process.env.REACT_APP_H2_DIGITAL_API_URL}/user-details/user-exists/${email}`,
      { signal }
    );
    return result;
  }
}
const SUserDetailsService = new UserDetailsService();
export default SUserDetailsService;
